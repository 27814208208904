import React from 'react';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import BoxedContainer from '../layouts/BoxedContainer';
import NoticeDetailsBanner from './NoticeDetailsBanner';
import DownloadNoticeCard from '../layouts/DownloadNoticeCard';

export default function NoticeDetails({ data, dir }) {
  const {
    title,
    noticeCategory,
    noticeDate,
    description,
    noticeBody,
    downloadPdf,
    downloadPdfTitle,
    cardTitle,
  } = data;

  return (
    <section className="bg-bg-2 pb-20 pt-[130px] lg:pb-[180px] lg:pt-[200px]">
      <BoxedContainer>
        <NoticeDetailsBanner
          title={title}
          noticeCategory={noticeCategory}
          noticeDate={noticeDate}
          dir={dir}
        />
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.3 }}
          viewport={{ once: true }}
          className="flex flex-col gap-x-20 gap-y-10 pt-10 lg:gap-y-20 lg:pt-[60px]
          xl:flex-row xl:gap-y-0 2xl:gap-x-[130px] 3xl:gap-x-[170px] 3xl:pt-[78px]">
          <div className="flex flex-col xl:w-[59%] 
              ltr:html-content-strong:font-space-semibold 
              rtl:html-content-strong:font-almarai rtl:html-content-strong:font-bold">
            {isEmpty(description) || (
              <div className="pb-5 lg:pb-10 a-child:underline hover:a-child:no-underline">
                <h5
                  className="ltr:font-space-regular rtl:font-almarai text-[18px] font-medium leading-8
                  text-primary lg:text-[20px] lg:leading-10 xl:text-xl xl:leading-[44px]"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            )}
            {isEmpty(noticeBody) || (
              <div
                className="rtl:font-almarai ltr:font-space-grotesk text-[15px] font-light leading-7 text-primary
              ul-list:pl-[22px] li-child:list-disc ol-section:pl-[40px]
              ol-parent:list-decimal xl:text-[16px] 3xl:text-base a-child:underline hover:a-child:no-underline"
                dangerouslySetInnerHTML={{ __html: noticeBody }}
              />
            )}
          </div>
          <div className="xl:w-[34%]">
            <DownloadNoticeCard
              dir={dir}
              item={downloadPdf}
              title={cardTitle}
              subtitle={downloadPdfTitle}
            />
          </div>
        </motion.div>
      </BoxedContainer>
    </section>
  );
}
