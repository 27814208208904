import React from 'react';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { dateToString, dateToNum, convertMonthToAr } from '../../helper/dateToString';
import { getTranslationByKey } from '../../utils/translation-helper';

export default function NoticeDetailsBanner({ title, noticeCategory, noticeDate, dir }) {

  const isArabic = dir === 'rtl';

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.3 }}
      viewport={{ once: true }}
      className="flex w-full flex-col gap-y-5 md:w-[550px] lg:w-[750px] xl:w-[895px] xl:gap-y-7
      3xl:w-[1192px] 3xl:gap-y-9"
    >
      {isEmpty(noticeCategory) || (
      <h5
        className="rtl:font-almarai ltr:font-space-grotesk text-[13px] font-light uppercase leading-7
        text-primary ltr:tracking-[3px] lg:text-[14px] ltr:lg:tracking-[5px]
        3xl:text-lg ltr:3xl:tracking-[8px]"
      >
        {getTranslationByKey(noticeCategory, noticeCategory)}
      </h5>
      )}
      {isEmpty(title) || (
      <h1
        className="text-gradient rtl:font-almarai ltr:font-space-grotesk text-[22px] font-light
      leading-10 md:text-[26px] lg:pr-[6rem] lg:text-[32px] lg:leading-[45px] xl:pr-0
      xl:text-[40px] xl:leading-[55px] 3xl:text-[56px] 3xl:leading-[88px]"
      >
        {title}
      </h1>
      )}
      {isEmpty(noticeDate) || (
      <span
        className="rtl:font-almarai ltr:font-space-grotesk text-[13px] font-light uppercase leading-7
        text-primary ltr:tracking-[3px] lg:text-[14px] ltr:lg:tracking-[5px]
        3xl:text-lg ltr:3xl:tracking-[8px]"
      >
        {isArabic ? convertMonthToAr(noticeDate) : dateToString(noticeDate) }
      </span> )}
    </motion.div>
  );
}
