import React from 'react';
import { isEmpty } from 'lodash';
import Button from './Button';
import Notice from '../../services/Notice';
import Icons from './Icons';
import { getTranslationByKey } from '../../utils/translation-helper';

export default function DownloadNoticeCard({ dir, item, title, subtitle }) {
  const isArabic = dir === 'rtl';
  const notice = new Notice();
  const allNotice = notice.get(isArabic ? 'ar' : 'en');
  const firstNotice = allNotice[0];
  const { parent } = firstNotice;
  const textLabel = getTranslationByKey('See all Regulatory Notices','See all Regulatory Notices')
  return (
    <div className="flex flex-col gap-y-5 xl:gap-y-8 3xl:gap-y-10">
      <h5
        className="rtl:font-almarai ltr:font-space-grotesk text-[15px] font-light uppercase leading-8
    ltr:tracking-[3px] text-primary lg:text-[16px] xl:text-base ltr:xl:tracking-[8px]"
      >
        {getTranslationByKey('Download Notices','Download Notices')}
      </h5>
      <div
        className="flex flex-col gap-y-4 border-b-2 border-b-bg-5 bg-white px-5 pb-[30px]
        pt-5 lg:gap-y-8 lg:pb-[55px] xl:pt-10 2xl:px-10 3xl:gap-y-10 3xl:pb-[70px] 3xl:pt-[48px]"
      >
        <div className="flex flex-col gap-y-4 lg:gap-y-6">
          <h4
            className="rtl:font-almarai ltr:font-space-grotesk text-[17px] font-light leading-7 text-primary
            lg:text-[20px] lg:leading-10 3xl:text-xl 3xl:leading-[44px]"
          >
            {subtitle}
          </h4>
        </div>
        {isEmpty(item) || (
          <a
            href={item.url}
            target={item.target}
            rel="noreferrer"
            className="flex items-start gap-x-5"
          >
            <Icons icon="download" className="top-1 relative min-w-[32px]" />
            <span
              className="rtl:font-almarai ltr:font-space-grotesk text-[17px] leading-7 text-primary lg:text-[20px]
                lg:leading-10 3xl:text-xl 3xl:leading-[44px]"
            >
              {item.name}
            </span>
          </a>
        )}
      </div>
      <div className="pt-5 3xl:pt-10">
        <Button
          url={parent.url}
          label={textLabel}
          variant="primaryBlack"
          ariaLabel={textLabel}
          text="uppercase rounded-[100rem] 3xl:w-[22.5rem] w-fit lg:!px-10 !px-5 w-max-fit
          rtl:font-almarai ltr:font-space-grotesk font-light !text-[15px]"
        />
      </div>
    </div>
  );
}
