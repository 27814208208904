import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { pageFragments, settingsFragments } from '../fragments/preview';
import Page from '../templates/page';
import News from '../templates/news';
import Notice from '../templates/notice';
import Registry from '../templates/registry';

export default function Preview() {
  const [varaPage, setVaraPage] = useState(null);
  const [varaSettings, setVaraSettings] = useState(null);
  const [type, setType] = useState(null);
  const isBrowser = typeof window !== 'undefined';
  const queryParams = new URLSearchParams(isBrowser && window.location.search);
  const [message, setMessage] = useState('Loading Preview');
  const langCode = queryParams.get('lang') || 'en-US';
  const id = queryParams.get('id');
  const loadPage = () => {
    const query = `
      ${pageFragments}
      query ($id: ID!, $lang: String!) {
        content(id: $id, culture: $lang, preview: true) {
          ...on NewVaraPage {
            ...Page
          }
          ...on VaraNews {
            ...News
          }
          ...on VaraNotice {
            ...Notice
          }
          ...on VaraRegistry {
            ...Registry
          }
        }
      }
    `;

    const options = {
      method: 'POST',
      url: 'https://graphql.umbraco.io',
      headers: {
        'content-type': 'application/json',
        'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
        'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
      },
      data: {
        query,
        variables: {
          id,
          lang: langCode,
        },
      },
    };
    axios
      .request(options)
      .then((response) => {
        if (type === 'newVaraPage') {
          setVaraPage({ umbraco: { newVaraPage: response.data.data.content } });
        }
        if (type === 'varaNews') {
          setVaraPage({ umbraco: { varaNews: response.data.data.content } });
        }
        if (type === 'varaNotice') {
          setVaraPage({ umbraco: { varaNotice: response.data.data.content } });
        }
        if (type === 'varaRegistry') {
          setVaraPage({ umbraco: { varaRegistry: response.data.data.content } });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPageType = async () => {
    const options = {
      method: 'POST',
      url: 'https://graphql.umbraco.io',
      headers: {
        'content-type': 'application/json',
        'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
        'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
      },
      data: {
        query: `
            query ($id: ID!, $lang: String!) {
                content(id: $id, culture: $lang, preview: true) {
                  contentTypeAlias
                }
            }
            `,
        variables: {
          id,
          lang: langCode,
        },
      },
    };

    axios
      .request(options)
      .then((response) => {
        setType(response.data.data.content.contentTypeAlias);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSettings = async () => {
    const options = {
      method: 'POST',
      url: 'https://graphql.umbraco.io',
      headers: {
        'content-type': 'application/json',
        'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
        'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
      },
      data: {
        query: `
          query ($lang: String) {
            allNewVaraSettings(culture: $lang) {
              ${settingsFragments}
            }
          }
      `,
        variables: {
          lang: langCode,
        },
      },
    };
    axios
      .request(options)
      .then((response) => {
        setVaraSettings(response.data.data.allNewVaraSettings.items[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!id) {
      setMessage('No id found');
      return;
    }
    getPageType();
  }, []);
  useEffect(() => {
    if (!type) return;
    getSettings();
  }, [type]);

  useEffect(() => {
    if (!varaSettings) return;
    loadPage();
  }, [varaSettings]);

  return isEmpty(varaPage) || isEmpty(varaSettings) ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        hight: '100%',
      }}
    >
      <h1>{message}</h1>
    </div>
  ) : (
    <>
      {type === 'newVaraPage' && (
        <Page
          pageContext={{
            langCode: langCode.split('-')[0],
            lang: langCode,
            settings: varaSettings,
          }}
          data={varaPage}
        />
      )}
      {type === 'varaNews' && (
        <News
          pageContext={{
            langCode: langCode.split('-')[0],
            lang: langCode,
            settings: varaSettings,
          }}
          data={varaPage}
        />
      )}
      {type === 'varaNotice' && (
        <Notice
          pageContext={{
            langCode: langCode.split('-')[0],
            lang: langCode,
            settings: varaSettings,
          }}
          data={varaPage}
        />
      )}
      {type === 'varaRegistry' && (
        <Registry
          pageContext={{
            langCode: langCode.split('-')[0],
            lang: langCode,
            settings: varaSettings,
          }}
          data={varaPage}
        />
      )}
    </>
  );
}
