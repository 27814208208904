import { graphql } from 'gatsby';
import * as React from 'react';
import { GlobalContext } from '../context/GlobalContext';
import Header from '../components/header/Header';
import Seo from '../components/Seo';
import Footer from '../components/footer/Footer';
import NoticeDetails from '../components/notice-details/NoticeDetails';
import resolveInternalLinks from '../helper/resolveInternalLinks';

export default function Notice({
  data: {
    umbraco: { varaNotice },
  },
  pageContext: { langCode, settings, slug },
}) {
  resolveInternalLinks(settings);
  resolveInternalLinks(varaNotice);

  const translations = settings?.translations ? settings?.translations?.map((t) => t.content) : [];
  const dir = langCode === 'ar' ? 'rtl' : 'ltr';

  const values = {
    langCode,
    translations,
    settings,
    slug,
    dir,
  };

  const memo = React.useMemo(() => values, [values]);

  return (
    <GlobalContext.Provider value={memo}>
      <Seo lang={langCode} page={varaNotice} dir={dir} />
      <Header
        data={settings}
        dir={dir}
        lang={langCode}
        currentPage={{
          id: varaNotice.id,
          url: varaNotice.url,
          udi: `umb://document/${varaNotice.id.replace(/-/g, '')}`,
        }}
      />
      <NoticeDetails data={varaNotice} dir={dir} />
      <Footer data={settings} dir={dir} lang={langCode} currentPage={varaNotice.id} />
    </GlobalContext.Provider>
  );
}

export const query = graphql`
  query NoticeQuery($id: ID!, $lang: String!) {
    umbraco {
      varaNotice(id: $id, culture: $lang) {
        title
        subtitle
        cssClass
        description
        featuredImage {
          url
        }
        id
        metadataDescription
        metadataKeywords
        metadataTitle
        name
        noticeBody
        noticeCategory
        noticeDate
        noticeIsActive
        noticeRegistry {
          name
          url
        }
        cardTitle
        downloadPdfTitle
        downloadPdf {
          name
          url
          target
        }
        socialMediaImage {
          url
        }
      }
    }
  }
`;
