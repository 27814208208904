export const settingsFragments = `
  items {
    googleAnalyticsCode
    googleMapsAPICode
    contentTypeAlias
    facebook
    twitter
    instagram
    linkedIn
    youtube
    tikTok
    darkLogo {
      url
    }
    lightLogo {
      url
    }
    links {
      name
      target
      url
      udi
    }

    footerMenu {
      content {
        ... on LinkGroup {
          urls {
            name
            target
            url
            udi
          }
        }
      }
    }
    footerText
    copyrightText
    bottomLinks {
      name
      target
      url
      udi
    }
    mainNavigation {
      content {
        ... on NavigationItem {
          subtitle
          color
          link {
            name
            target
            url
            udi
          }
          itemChildren {
            content {
              ... on NavigationItem {
                subtitle
                link {
                  name
                  target
                  url
                  udi
                }
                itemChildren {
                  content {
                    ... on NavigationItem {
                      subtitle
                      link {
                        name
                        target
                        url
                        udi
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    translations {
      content {
        ... on Translation {
          key
          value
        }
      }
    }
  }
`;

export const pageFragments = `
fragment Link on Link {
  name
  target
  url
  udi
}

fragment News on VaraNews {
  title
  subtitle
  cssClass
  description
  featuredImage {
    url
  }
  id
  metadataDescription
  metadataKeywords
  metadataTitle
  name
  newsBody
  newsCategory
  newsDate
  socialMediaImage {
    url
  }
  pDF {
    url
  }
  parent {
    url
  }
}

fragment Notice on VaraNotice {
  title
  subtitle
  cssClass
  description
  featuredImage {
    url
  }
  id
  metadataDescription
  metadataKeywords
  metadataTitle
  name
  noticeBody
  noticeCategory
  noticeDate
  noticeIsActive
  noticeRegistry {
    name
    url
  }
  cardTitle
  downloadPdfTitle
  downloadPdf {
    name
    url
    target
  }
  socialMediaImage {
    url
  }
}

fragment Registry on VaraRegistry {
  id
  name
  url
  title
  subtitle
  description
  featuredImage {
    url
  }
  cssClass
  metadataTitle
  metadataDescription
  metadataKeywords
  socialMediaImage {
    url
  }
  bannerText
  bannerSubtitle
  registrySubtitle
  registryTitle
  vASPName
  vASPReference
  registryAddress
  registryWebsite
  alertTitle
  alertDescription
  licenseTitle
  vARALicence
  vARAStatus
  licenseType
  licenseDescription
  varaReferenceNumber
  licenseIssuanceDate
  licenseActivities
}

fragment Page on NewVaraPage {
  contentTypeAlias
  id
  linesAnimationName
  name
  url
  title
  description
  metadataTitle
  metadataDescription
  metadataKeywords
  disableSearchEngineIndexing
  createDate
  updateDate
  featuredImage {
    url
  }
  socialMediaImage {
    url
  }
  cssClass
  newVaraBody {
    content {
        contentTypeAlias
        ... on VaraFAQ {
          text
          title
          subtitle
          varaFAQQuestions {
            content {
              ... on VaraFAQItem {
                varaFAQCategory
                varaFAQQuestion
                varaFAQAnswer
              }
            }
          }
        }
        ... on VaraQuote {
          image {
            url
          }
          personName
          personTitle
          quote
        }
        ... on VaraPartners {
          title
          text
          varaPartnersList {
            content {
              ... on VaraPartner {
                partnerImage {
                  url
                }
                partnerLink {
                  ...Link
                }
              }
            }
          }
        }
        ... on VaraTwoCards {
          cardTitle
          card1Title
          card1Text
          card1Email
          card1Link {
            ...Link
          }
          card2Title
          card2Text
          card2Email
          card2Link {
            ...Link
          }
        }
        ... on VaraThreeCards {
          cardTitle
          card1Title
          card1Text
          card1Email
          card1Link {
            ...Link
          }
          card2Title
          card2Text
          card2Email
          card2Link {
            ...Link
          }
          card3Title
          card3Text
        }
        ... on VaraMissions {
          title
          text
          varaMissionList {
            content {
              ... on VaraMissionItem {
                missionIcon {
                  url
                }
                missionText
              }
            }
          }
        }
        ... on VaraNewsList {
          subtitle
          title
          bannerText
          contentTypeAlias
        }
        ... on VaraTextBlock {
          cssClass
          htmltext
        }
        ... on VaraNoticeList {
          title
          subtitle
          noticeBannerText
        }
        ... on VaraPageBanner {
          image {
            url
          }
          links {
            ...Link
          }
          subtitle
          htmltext
          title
          items {
            content {
              ... on VaraHomeBannerCardItem {
                title
                text
                link {
                  ...Link
                }
                ariaLabel
              }
            }
          }
        }
        ... on VaraNotificationBanner {
          title
          htmlText
        }
        ... on VaraRegulations {
          cssClass
          link {
            ...Link
          }
          title
          htmltext
          varaDisclaimer
          varaRulebooksList {
            content {
              ... on VaraRulebookItem {
                rulebookTitle
                rulebookURLs {
                  ...Link
                }
              }
            }
          }
        }
        ... on VaraContactForm {
          formId
          contactSubtitle
          contactText
          contactTitle
          privacyPolicyMessage
          contentTypeAlias
        }
        ... on VaraRegistryList {
          title
          subtitle
          htmltext
          varaRegistryList {
            content {
              ... on VaraRegistryItem {
                activeNotice
                issuanceDate
                licenseNumber
                registryName
                varaRegistyCategory
              }
            }
          }
        }
        ... on VaraWhyDubaiBody {
          title
          subtitle
          contentTypeAlias
          htmltext
          varaStats {
            content {
              ... on VaraStatsItem {
                mediaName
                title
                subtitle
                fontSm
              }
            }
          }
        }
        ... on VaraLicenceStages {
          title
          htmltext
          licenceStagesDisclaimer
          image {
            url
          }
          licenceTitle
          licenceText
          varaLicenceStagesList {
            content {
              ... on VaraLicenseStage {
                title
                htmltext
                links {
                  ...Link
                }
              }
            }
          }
        }
        ... on VaraWhyDubaiBanner {
          title
          textCol
          subtitle
          contentTypeAlias
          links {
            ...Link
          }
          image {
            url
          }
        }
        ... on VaraOtherActivities {
          title
          text
          varaOtherActiities {
            content {
              ... on VaraTextBlockWithIcon {
                title
                htmltext
                policyPrincipleIcon {
                  url
                }
              }
            }
          }
        }
        ... on VaraListOfActivities {
          title
          htmltext
          zindex
          varaActivitiesDisclaimer
          keyLists
          varaActivityCards {
            content {
              ... on VaraActivityCard {
                title
                htmltext
                link {
                  ...Link
                }
              }
            }
          }
        }
        ... on VaraPolicyPrinciples {
          title
          policyText
          policyPrinciplesDisclaimer
          policyPrinciples {
            content {
              ... on VaraTextBlockWithIcon {
                title
                htmltext
                policyPrincipleIcon {
                  url
                }
              }
            }
          }
          policyKeyList {
            content {
              ... on VaraKeyLists {
                title
                lists
              }
            }
          }
        }
        ... on VaraDirectoryServices {
          title
          varaDirectoryBlocks {
            content {
              ... on VaraGeneralTextBlock {
                title
                htmltext
                link {
                  ...Link
                }
                cssClass
              }
            }
          }
        }
        ... on VaraPageBannerLongText {
          title
          htmltext
          subtitle
          links {
            ...Link
          }
          image {
            url
          }
          items {
            content {
              ... on VaraHomeBannerCardItem {
                title
                text
                link {
                  ...Link
                }
                ariaLabel
              }
            }
          }
        }
        ... on VaraCTAWithTextAndLink {
          cssClass
          link {
            ...Link
          }
          htmltext
          title
          image {
            url
          }
        }
        ... on VaraMarketingGuidlinesDownloads {
          title
          htmltext
          varaGuidlineFiles {
            url
            name
            type
            target
          }
          varaGuidlinesDisclaimer
        }
        ... on VaraCtaWithTwoColumnText {
          cssClass
          link {
            ...Link
          }
          textcol1
          textcol2
          image {
            url
          }
          ariaLabel
        }
        ... on VaraTextWithImage {
          image {
            url
          }
          contentTypeAlias
          title
          textCol
          cssClass
        }
        ... on VaraHomeNewsBlock {
          cssClass
          image {
            url
          }
          link {
            ...Link
          }
          htmltext
          title
        }
        ... on VaraInnerPageBanner {
          subtitle
          title
          zindex
          bannerHtmlContent
          contentTypeAlias
          image {
            url
          }
          links {
            name
            target
            url
          }
          textCol
          textCol2
          linksTitle
          lists {
            content {
              ... on VaraKeyLists {
                title
                lists
              }
            }
          }
          pageDisclaimer
        }
        ... on VaraCTACardWithBackgroundImage {
          cTATitle
          cTAText
          backgroundImage {
            url
          }
          cTAButton {
            name
            url
          }
        }
        ... on VaraComplaintBlock {
          complaintFormId
          complaintTitle
          complaintAlertText
          complaintAlertTitle
          complaintText
          contentTypeAlias
        }
        ... on VaraHTMLContent {
          contentTypeAlias
          htmlTitle
          htmlText
        }
        ... on VaraDubaiFreeZone {
          title
          subtitle
          htmlText
          contentTypeAlias
          image {
            url
          }
          dubaiFreezoneMarkers {
            content {
              ... on VaraDubaiFreezoneMarker {
                title
                location
              }
            }
          }
        }
        ... on VaraWhyInvestInDubai {
          title
          htmlText
          contentTypeAlias
          varaWhyInvestInDubaiItem {
            content {
              ... on VaraWhyInvestInDubaiItem {
                itemTitle
                itemText
                image {
                  url
                }
              }
            }
          }
        }
        ... on VaraIntroWithTwoColumnText {
          contentTypeAlias
          introTitle
          introDescription
          introText
          introDescription
        }
        ... on VaraIntroWithOneColumn {
          contentTypeAlias
          title
          description
          htmlText
        }
        ... on VaraNexusDigitalInnovation {
          title
          htmlText
          contentTypeAlias
          backgroundImage {
            url
          }
        }
        ... on VaraApplicableRulebooks {
          title
          compulsoryTitle
          contentTypeAlias
          otherTitle
          compulsoryLinks {
            name
            url
          }
          otherLinks {
            name
            url
          }
        }
        ... on VaraApplicationDocumentation {
          title
          contentTypeAlias
          downloadLink {
            name
            url
          }
          applicationText
          items {
            content {
              ... on VaraApplicationDocumentationList {
                title
                list {
                  content {
                    ... on VaraDocumentationParagraphList {
                      list
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
}
`;
